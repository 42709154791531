import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Typography, Button, useTheme } from '@mui/material';
import Link from 'next/link';

const useStyles = makeStyles()(() => ({
  icon: {
    width: 400,
  },
}));

function PageNotFound(props) {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={491}
      height={206}
      viewBox="0 0 491 206"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h491v206H0z" />
        </clipPath>
      </defs>
      <g data-name="Tavola da disegno \u2013 1" clipPath="url(#prefix__a)">
        <g transform="translate(-6.122 -69.653)">
          <ellipse
            data-name="Ellisse 19"
            cx={245.697}
            cy={16.266}
            rx={245.697}
            ry={16.266}
            transform="translate(6.122 239.59)"
            fill={'#f2f2f2'}
          />
          <path
            data-name="Tracciato 26"
            d="M242.401 77.789c-40.031 0-68.772 25.683-68.772 85.609 0 67.916 28.742 85.894 68.772 85.894 32.1 0 58.049-12.665 67.107-52.014a152.222 152.222 0 003.378-33.88c0-70.77-30.453-85.609-70.485-85.609zm.263 148.959c-27.726 0-42.211-16.266-42.211-63.307 0-41.506 15.078-63.394 42.8-63.394s42.8 14.377 42.8 63.394c.009 45.854-15.662 63.307-43.388 63.307z"
            fill={'#a1a2b1'}
          />
          <path
            data-name="Tracciato 27"
            d="M149.659 201.922h-16.551v-48.738a9.761 9.761 0 00-9.761-9.761h-3.879a9.761 9.761 0 00-9.761 9.761v48.739H63.023a5.572 5.572 0 01-4.927-8.175l49.411-93.519a9.761 9.761 0 00-4.462-13.386l-2.917-1.377a9.761 9.761 0 00-12.776 4.225L27.089 202.422a13.235 13.235 0 00-1.563 6.24 13.235 13.235 0 0013.235 13.235h70.947v30.819a11.7 11.7 0 0011.7 11.7 11.7 11.7 0 0011.7-11.7v-30.819h16.551a9.988 9.988 0 009.988-9.988 9.988 9.988 0 00-9.988-9.988z"
            fill={color}
          />
          <path
            data-name="Tracciato 28"
            d="M456.138 201.922h-16.551v-48.738a9.761 9.761 0 00-9.761-9.761h-3.875a9.761 9.761 0 00-9.761 9.761v48.738h-46.687a5.572 5.572 0 01-4.927-8.175l49.411-93.519a9.761 9.761 0 00-4.462-13.386l-2.917-1.377a9.761 9.761 0 00-12.776 4.225l-60.263 112.732a13.235 13.235 0 00-1.563 6.24 13.235 13.235 0 0013.235 13.235h70.946v30.819a11.7 11.7 0 0011.7 11.7 11.7 11.7 0 0011.7-11.7v-30.819h16.551a9.988 9.988 0 009.988-9.988 9.988 9.988 0 00-9.988-9.988z"
            fill={color}
          />
          <path
            data-name="Tracciato 29"
            d="M154.225 196.212h-16.551v-48.736a9.761 9.761 0 00-9.76-9.764h-3.882a9.761 9.761 0 00-9.761 9.761v48.739H67.59a5.572 5.572 0 01-4.927-8.175l49.411-93.519a9.761 9.761 0 00-4.462-13.386l-2.917-1.377a9.761 9.761 0 00-12.776 4.225L31.656 196.712a13.236 13.236 0 00-1.563 6.24h0a13.235 13.235 0 0013.239 13.238h70.946v30.822a11.7 11.7 0 0011.7 11.7h0a11.7 11.7 0 0011.7-11.7V216.19h16.551a9.988 9.988 0 009.988-9.988h0a9.988 9.988 0 00-9.988-9.988z"
            fill="none"
            stroke={'#3f3d56'}
            strokeMiterlimit={10}
          />
          <path
            data-name="Tracciato 30"
            d="M464.699 196.212h-16.551v-48.736a9.761 9.761 0 00-9.761-9.764h-3.878a9.761 9.761 0 00-9.761 9.761v48.739h-46.684a5.572 5.572 0 01-4.927-8.175l49.411-93.519a9.761 9.761 0 00-4.462-13.386l-2.917-1.377a9.761 9.761 0 00-12.776 4.225l-60.261 112.732a13.235 13.235 0 00-1.563 6.24h0a13.235 13.235 0 0013.235 13.235h70.946v30.825a11.7 11.7 0 0011.7 11.7h0a11.7 11.7 0 0011.7-11.7V216.19h16.551a9.988 9.988 0 009.988-9.988h0a9.988 9.988 0 00-9.99-9.99z"
            fill="none"
            stroke={'#3f3d56'}
            strokeMiterlimit={10}
          />
          <path
            data-name="Tracciato 31"
            d="M251.534 72.652c-40.031 0-68.772 25.683-68.772 85.609 0 67.916 28.742 85.894 68.772 85.894s70.484-19.69 70.484-85.894c.001-70.77-30.453-85.609-70.484-85.609zm.263 148.959c-27.726 0-42.211-16.266-42.211-63.307 0-41.506 15.078-63.394 42.8-63.394s42.8 14.377 42.8 63.394c0 45.854-15.671 63.307-43.4 63.307z"
            fill="none"
            stroke={'#3f3d56'}
            strokeMiterlimit={10}
          />
        </g>
      </g>
    </svg>
  );
}

export default function Error404(props) {
  const { classes } = useStyles();

  return (
    <div
      style={{
        width: '100%',
        height: 'calc(100vh - 120px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <PageNotFound className={classes.icon} />
      <Typography variant="h1">Page not found</Typography>
      <Typography variant="body1" style={{ marginTop: 12, opacity: 0.6 }}>
        We can&apos;t seem to find the page you&apos;re looking for
      </Typography>
      <Link href="/">
        <Button
          variant="contained"
          style={{
            marginTop: 20,
          }}
        >
          Homepage
        </Button>
      </Link>
    </div>
  );
}
